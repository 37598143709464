import React, { useState } from "react"
import { Link } from "gatsby"
import shape from "../../assets/images/shape/vector-shape6.png"
import FirebaseApp from "./../../Firebase"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io"
import "./accordian.css"

let database = FirebaseApp.database().ref("/")

class JobsAccordian extends React.Component {
  constructor() {
    super()
    this.state = {
      positionsForInternship: [],
    }
  }

  componentDidMount = () => {
    let { positionsForInternship } = this.state
    database.child("Jobs/").once("child_added", snapshot => {
      var data = snapshot.val()
      positionsForInternship.push(data)
      this.setState({ positionsForInternship: positionsForInternship })
    })
  }

  openAccordian = index => {
    let { positionsForInternship } = this.state
    for (var i = 0; i < positionsForInternship.length; i++) {
      if (i === index) {
        positionsForInternship[index].open = !positionsForInternship[index].open
      } else {
        positionsForInternship[i].open = false
      }
    }
    this.setState({ positionsForInternship: positionsForInternship })
  }
  render() {
    let { positionsForInternship } = this.state
    return (
      <div className="courses-area bg-fafafb pt-100 pb-70">
        <div className="container">
          <div className="section-title">
            <h2 id="form">We are offering jobs in following areas</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-12 col-md-12">
              {positionsForInternship.length !== 0 ? (
                positionsForInternship.map((val, i) => {
                  return (
                    <div className="accordian">
                      <div className="accordianTitleSec">
                        <div>
                          <p className="accordianTitle">{val.title}</p>
                        </div>
                        <div
                          onClick={() => this.openAccordian(i)}
                          style={{ cursor: "pointer" }}
                        >
                          {val.open ? (
                            <IoIosArrowUp color="#212529" size="24" />
                          ) : (
                            <IoIosArrowDown color="#212529" size="24" />
                          )}
                        </div>
                      </div>
                      {val.open ? (
                        <div className="accordianDetailsDiv">
                          <p>{val.description}</p>
                          <p>{val.location}</p>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              flexDirection: "row",
                            }}
                          >
                            <a href="#jobform">
                            <button
                              className="applyBtn"
                              type="button"
                              onClick={() => this.props.applyFunc(val.title)}
                            >
                              Apply Now
                            </button>
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )
                })
              ) : (
                <p className="notFoundTxt">
                  There is no any place for jobs available
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="vector-shape6">
          <img src={shape} alt="course" />
        </div>
      </div>
    )
  }
}
export default JobsAccordian
