import React, { useState, useEffect } from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import JobBanner from "../components/jobs/jobsbanner"
import { Link } from "gatsby"
import Offering from "../components/internship/offering"
import privacyPolicy from "../assets/images/privacy-policy.jpg"
import JobForm from "../components/jobs/jobform"
import JobOffering from "../components/jobs/joboffring"
import JobsAccordian from "../components/jobs/accordian"

const Jobs = () => {
  const [showJobForm, setShowJobForm] = useState(false)
  const [jobTitle, SetJobTitle] = useState("")
  const applyNowFunction = title => {
    SetJobTitle(title)
    // window.location.href = "#jobform"
  }
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Jobs"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Jobs"
      />
      <JobBanner />
      <JobsAccordian applyFunc={applyNowFunction} />
      {jobTitle !== "" ? (
        <section className="privacy-policy-area ptb-100">
          {/* <div id="jobApplyForm"> */}
          <JobForm selectedJob={jobTitle} />
          {/* </div> */}
        </section>
      ) : null}
      <Footer />
    </Layout>
  )
}

export default Jobs
