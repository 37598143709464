import React, { useState, useEffect } from "react"
import starIcon from "../../assets/images/star-icon.png"
import contact from "../../assets/images/contact.png"
import FirebaseApp from "./../../Firebase"

let database = FirebaseApp.database().ref("/")

const JobForm = props => {
  const [messageShow, setMessageShow] = useState(false)
  const [message, setMessage] = useState("")
  const [messageType, setMessageType] = useState("red")
  const [uploadProgress, setUploadProgress] = useState("")
  const [btnDisable, setBtnDisable] = useState(false)
  const [btnText, setBtnText] = useState("Submit Now")
  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    applyingin: props.selectedJob,
    qualifications: "",
    cv: "",
  })

  // {
  //   "rules": {
  //     ".read": true,
  //     ".write": "auth.uid != null"
  //   }
  // }

  const handle_change = e => {
    setInputValues({
      ...inputValues,
      [e.target.name]: e.target.value,
    })
    setMessageShow(false)
  }
  console.log(inputValues)
  const sendEmail = () => {
    if (inputValues.name === "") {
      setMessage("name is required")
      setMessageShow(true)
      setMessageType("red")
    } else if (inputValues.email === "") {
      setMessage("email address is required")
      setMessageShow(true)
      setMessageType("red")
    } else if (inputValues.qualifications === "") {
      setMessage("qualification is required")
      setMessageShow(true)
      setMessageType("red")
    } else if (inputValues.cv === "") {
      setMessage("upload your cv")
      setMessageShow(true)
      setMessageType("red")
    } else {
      setBtnDisable(true)
      setBtnText("Sending...")
      database
        .child("/JobsRequests/")
        .push(inputValues)
        .then(res => {
          console.log("====res====", res)
          setInputValues({
            name: "",
            email: "",
            mobileNumber: "",
            applyingin: "Web Development",
            qualifications: "",
            cv: "",
          })
          setBtnText("Submit Now")
          setBtnDisable(false)
          setUploadProgress("")
          setMessage("Applied successfully")
          setMessageShow(true)
          setMessageType("green")
        })
        .catch(err => {
          console.log("====error====", err)
        })
    }
    setTimeout(() => {
      setMessage("")
      setMessageShow(false)
      setMessageType("green")
    }, 3000)
  }
  const upload_File = async e => {
    var file = e.target.files[0]
    if (e.target.files.length !== 0) {
      setUploadProgress("Uploading..........")
      setBtnDisable(true)
      let ref = FirebaseApp.storage().ref("/").child(`CV/${file.name}`)
      await ref.put(file)
      ref.getDownloadURL().then(url => {
        setUploadProgress("Uploaded")
        setInputValues({ ...inputValues, cv: url })
        setBtnDisable(false)
      })
    } else {
      this.setState({ _image_type_error: "" })
    }
  }
  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">FILL THE FORM BELOW</span>
          <h2 id="jobform">Apply Now</h2>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <img src={contact} alt="contact" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              <form id="contactForm">
                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <label>Full Name</label>
                      <input
                        name="name"
                        type="text"
                        className="form-control"
                        value={inputValues.name}
                        onChange={handle_change}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={inputValues.email}
                        onChange={handle_change}
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>Phone number (optional)</label>
                      <input
                        type="text"
                        name="mobileNumber"
                        value={inputValues.mobileNumber}
                        onChange={handle_change}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>Qualifications</label>
                      <input
                        type="text"
                        name="qualifications"
                        value={inputValues.qualifications}
                        onChange={handle_change}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>Upload your CV</label>
                      <input
                        type="file"
                        name="cv"
                        onChange={upload_File}
                        className="form-control-file "
                      />
                      <p
                        style={{
                          color: "green",
                        }}
                      >
                        {uploadProgress}
                      </p>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>Applying in:</label>
                      <select
                        value={inputValues.applyingin}
                        onChange={handle_change}
                        className="form-control"
                        name="applyingin"
                      >
                        <option value={props.selectedJob}>
                          {props.selectedJob}
                        </option>
                        {/* <option value="Web Development">Web Development</option>
                        <option value="Word">WordPress</option>
                        <option value="Graphic Designing">
                          Graphic Designing
                        </option>
                        <option value="Ecommerce">Ecommerce</option>
                        <option value="Marketing">Marketing</option> */}
                      </select>
                    </div>
                  </div>
                  {messageShow ? (
                    <div className="col-lg-12 col-md-12">
                      <p style={{ color: messageType }}>{message}</p>
                    </div>
                  ) : null}
                  <div className="col-lg-12 col-md-12">
                    <button
                      type="button"
                      className="default-btn"
                      onClick={() => sendEmail()}
                      disabled={btnDisable}
                    >
                      <i className="flaticon-tick"></i>
                      {btnText} <span></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default JobForm
